<!-- Cobros de Remesas por número de factura -->

<template>
  <div class="cobros_pagos_facturas">   
      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { accion:'cerrar' })">
            </base_Header>                  
          </div>

          <v-btn
            v-bind="$cfg.btra_cfg.standard"            
            title="Filtrar Facturas">
              <v-icon dark>{{ "mdi-filter" }}</v-icon>
          </v-btn>

          <!-- Contenido -->
          <div class="contenedor conflex" style="width:600px;justify-content:center"> 
            
              <div class="columna" style="width:50%">
                <v-textarea
                  v-bind="$textarea"
                  v-model="schema.ctrls.txt.value"
                  :label="schema.ctrls.txt.label"
                  rows="6"
                  no-resize>
                </v-textarea>
              </div>

              <div class="columna" style="width:50%">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.fras.value"
                  :label="schema.ctrls.fras.label">
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.fras2.value"
                  :label="schema.ctrls.fras2.label">
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.imp.value"
                  :label="schema.ctrls.imp.label">
                </v-text-field>

                <vlinput type="date" v-model="schema.ctrls.fh.value"> </vlinput>
              </div>

            
          </div>

          <div class="conflex">
            <v-select  
              v-bind="$select"
              v-model="schema.ctrls.ban.value"
              :label="schema.ctrls.ban.label"
              :items="itemsCC"
              item-value="id"
              item-text="name">
            </v-select>

            <v-select  
              v-bind="$select"
              v-model="schema.ctrls.tipo.value"
              :label="schema.ctrls.tipo.label"
              :items="itemsTipos"
              item-value="id"
              item-text="name">
            </v-select>
          </div>

          <div class="conflex">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.asi.value"
              :label="schema.ctrls.asi.label">
            </v-text-field>
          </div>
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
      
  export default {
    mixins: [mixinFinder],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: ''},
      accionRow: { type:Object, default: null},
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            txt: { f:'txt', label:'Introduce Nº de Facturas', value:""},
            fras: { f:'fras', label:'Fras', value:""},
            fras2: { f:'fras2', label:'', value:""},
            imp: { f:'imp', label:'Importe', value:""},
            fh: { f:'fh', label:'Fecha', value:""},
            ban: { f:'ban', label:'C.C.', value:"0"},
            tipo: { f:'tipo', label:'Tipo', value:"0"},
            orden: { f:'orden', label:'Orden de Pago', value:""},
            asi: { f:'asi', label:'Asiento', value:""},
          }
        },
        api:'',
        stName:'stFcobros_pagos_facturas',
        btra_permisos:{ extra:{}},

        itemsCC: [],
        itemsTipos: []
      };
    },


    created() {
      this.ini_data();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Cobro Remesa por Nº de Facturas"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "filtrar", icono: "mdi-filter", title:'Filtrar Facturas' }
        ];
      
        // items adicionales
        // array de cuentas bancarias
        this.itemsCC= [];

        // array de tipos de operaciones
        this.itemsTipos= []
      },



    }
  };
</script>
